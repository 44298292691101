<template>
  <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6 thumb" @click="redirectToStore">
    <div class="thumb-inside">
      <a  class="thumbnail" >
        <img v-if="top.image" class="img-responsive" :src="top.image" alt />
        <img v-else class="img-responsive" src="../../assets/placeholder.png" alt />
      </a>

    </div>
    <div class="store_name text-center">
      <h5>{{ top.name }}</h5>
    </div>
  </div>
</template>

<script>
export default {
  props: ["top"],
  methods: {
    redirectToStore() {
      this.$router.push({
        name: "SearchStore",
        params: { code: this.top._id, slug: this.top.slug },
      });
    },
  },
};
</script>